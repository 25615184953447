import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.directive('highlightjs', {
  deep: true,
  bind: function (el, binding) {
      // 使用highlight.js对元素进行代码高亮
      let targets = el.querySelectorAll('code');
      targets.forEach((target) => {
          hljs.highlightBlock(target);
      });
  },
  componentUpdated: function (el, binding) {
      // 在组件更新时重新应用代码高亮
      let targets = el.querySelectorAll('code');
      targets.forEach((target) => {
          hljs.highlightBlock(target);
      });
  }
});

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
