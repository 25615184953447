<template>
    <el-container
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.2)">
        <el-header style="height: 60px; position: fixed; top: 0; width: 100%; z-index: 999;">
            <img class="title_image" :src="imageSrc">
            <span style="font-family: 楷体; color: rainbow; font-size: 2em;" @click="GoToLogin">云量互动</span>
        </el-header>

        <el-main id="messageContainer" style="max-height: 92vh; overflow-y: auto;scrollbar-width: none; -ms-overflow-style: none;">
            <div v-for="(msg, index) in msgList" :key="index" :style="msg.type === 'image'? {marginTop:'20px'} : {}">
                <p class="index_p" :style="{ justifyContent: msg.role === 'system' ? 'flex-start' : 'flex-end' }">
                    <el-avatar v-if="msg.role === 'system'" :src="AI" style="float:right;"></el-avatar>
                    <span class="content index_span" :style="msg.type === 'image' ? { backgroundColor: 'transparent',margin: '-5px' } : {}">
                        <template v-if="msg.type === 'image'">
                            <img :src="msg.content" style="max-width: 50%; margin-top: 10px;border-radius: 5px;"/>
                        </template>
                        <template v-else>
                            <markdown :source="msg.content"></markdown>
                        </template>
                    </span>
                    <el-avatar v-if="msg.role === 'user'" :src="userInfo"></el-avatar>
                </p>
            </div>
        </el-main>

        <el-footer style="position: fixed; bottom: 0; width: 100%; z-index: 999;">
            <el-input v-model="input_txt" placeholder="请输入内容,输入清空清空历史记录" class="transparent-input" style="width: 60% ;z-index: 1;" @keyup.enter.native="fsh('txt')"></el-input>
            <el-button style="background-color: transparent; color: inherit;z-index: 900;" @click="fsh('txt')">发送</el-button>
            <el-button style="background-color: transparent; color: inherit;z-index: 900;" @click="fsh('image')">文生图</el-button>
        </el-footer>
    </el-container>   
</template>

<script>
import { get,post } from '@/store/request.js';
import Markdown from 'vue-markdown';

export default {
    components: {
        markdown: Markdown
    },
    data() {
        return {
            imageSrc:"", // 标题图标 
            input_txt:"",
            userInfo: "https://img.zcool.cn/community/01a6095f110b9fa8012066219b67d4.png@1280w_1l_2o_100sh.png",
            AI:"https://tse2-mm.cn.bing.net/th/id/OIP-C.wZKvyWR96aO-kKrNtzIXSwAAAA?rs=1&pid=ImgDetMain",
            msgList:[],

            isRequesting: false, // 添加一个标志位用于表示是否正在请求中
            isMessageDisplayed:false, // 
            loading:false, //
        };
    },
    methods: {
        GoToLogin(){
            this.$router.push('/login');
        },
        checkToken() {
            let token =localStorage.getItem('access_token')
            if (!localStorage.getItem('access_token') || 'undefined' == token) {
                // 弹出登录注册窗口的逻辑
                this.$message({
                    message: '请登录',
                    type: 'warning'
                });
                this.$router.push('/login'); // 跳转到登录页面
            }
        },
        scrollToBottom() {
            const container = document.getElementById('messageContainer');
            if (container) {
                container.scrollTop = container.scrollHeight;
            }
        },
        fsh(type) {
            if (this.isRequesting) {
                return; // 如果正在请求中，则不执行后续操作
            }
            
            let token = localStorage.getItem('access_token');
            if (!this.input_txt) {
                if (!this.isMessageDisplayed) {
                    this.isMessageDisplayed = true; // 添加标志位来表示消息已显示
                    this.$message({
                        message: '请输入内容',
                        type: 'warning',
                        onClose: () => {
                            this.isMessageDisplayed = false; // 在消息关闭时重置标志位
                        }
                    });
                }
                return;
            }
            
            this.isRequesting = true; // 设置为正在请求中
            this.loading = true;
            post('index', {
                "input_txt": this.input_txt,
                "type": type
            }, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((result) => {
                this.input_txt = '';
                this.msgList = result.data;
                this.isRequesting = false; // 请求完成后设置为非请求状态
                this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.isRequesting = false; // 请求完成后设置为非请求状态
                this.loading = false;
            });
        }
    },
    mounted() {
    let token =localStorage.getItem('access_token')
    import(`@/assets/favicon.png`).then((src) => {
        this.imageSrc = src.default;
    });
    this.checkToken()
    get('index', {},{
        headers: {
            Authorization: 'Bearer '+ token
        }
    }).then((result) => {
        this.msgList = result.data
    }).catch((err) => {
        this.$router.push('/login');
        console.log(err);
    });
    this.scrollToBottom();

  },
watch: {
    msgList() {
        this.$nextTick(() => {
            this.scrollToBottom();
        });
    }
},
};
</script>

<style>
.index_span pre{
    background-color: #454749;
    border-radius: 8px !important;
    padding-left: 10px;
    line-height: 190%;
}

.el-avatar img{
    margin: 0;
}
.index_p{
    display: flex;
    justify-content: center; 
    overflow: hidden;
}
.index_span{
    text-align: left;
    padding: 0 20px 0;
    color: #d6d6dd;
    background-color: #5d6063;
    margin: 0 3px;
    border-radius: 10px;
    justify-content: left; /* 水平居中 */
    max-width: 60%;
    display: inline-block; /* 让元素以块级元素显示，以便设置宽度 */
    box-sizing: border-box; /* 让元素的宽度包括内边距和边框 */
}

.el-input--suffix{
    background-color: transparent !important;
    background-color: #9b1d1d;
    width: 200px;
}
.transparent-input input {
    background-color: transparent !important;
}
.el-button+.el-button{
    margin-left: 1px !important;
}
.el-input{
    border-radius: 2px !important;
    /* width: 60% !important; */
}
.el-input {
    background: -webkit-linear-gradient(135deg,
            #0eaf6d,
            #ff6ac6 25%,
            #147b96 50%,
            #e6d205 55%,
            #2cc4e0 60%,
            #ff6384 95%,
            #08dfb4);
    /* 文字颜色填充设置为透明 */
    -webkit-text-fill-color: transparent;
    /* 背景裁剪，即让文字使用背景色 */
    -webkit-background-clip: text;
    /* 背景图放大一下，看着柔和一些 */
    -webkit-background-size: 200% 100%;
    /* 应用动画flowCss 12秒速度 无限循环 线性匀速动画*/
    -webkit-animation: flowCss 12s infinite linear;
    background-clip: text; /* Standard property for compatibility */
}
.el-header,
.el-footer {
    text-align: center;
    justify-content: center;
    border-radius: 2px;
    display: flex;
    align-items: center;
}

.el-aside {
    text-align: center;
    line-height: 200px;
}

.el-main {
    text-align: center;
    padding: 0 6% !important;
}

body > .el-container {
    margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
    line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
    line-height: 320px;
}
.title_image {
    height: 50px;
    margin-top: 4px;
}
</style>
<style scoped>
.transparent-input ::v-deep .el-input__inner {
    width: 100% !important; /* 确保覆盖 */
    z-index: 1 !important; /* 确保覆盖 */
    margin-bottom: 0 !important;
}
</style>