import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.withCredentials = true;

// 创建一个Axios实例，并设置默认配置
const axiosInstance = axios.create({
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use(
    function (config) {
        const access_token = localStorage.getItem("access_token");
        const isLoginPage = window.location.href.includes("/login");
        
        if (access_token && access_token !== 'null' && !isLoginPage) {
            config.headers["Authorization"] = `Bearer ${access_token}`;
        }
        
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            // 处理状态码为401的情况，重新跳转到login页面
            window.location.href = "#/login";
        }
        return Promise.reject(error);
    }
);

// 定义HTTP请求方法
export const get = (url, params) => axiosInstance.get(url, { params });
export const post = (url, data) => axiosInstance.post(url, data);
export const put = (url, data) => axiosInstance.put(url, data);
export const del = (url, params) => axiosInstance.delete(url, { params });

// 导出Axios实例
export default axiosInstance;
